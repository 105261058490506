module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito Sans","sans-serif"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prettier-build/gatsby-browser.js'),
      options: {"plugins":[],"types":["html"],"concurrency":20,"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://homecomfortair.net.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#0075c9","theme_color":"#0075c9","display":"minimal-ui","icon":"src/images/avatar-42x42.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ad248b66cafbea7596095bae36798402"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-90},
    },{
      plugin: require('../node_modules/gatsby-plugin-pinterest-twitter-facebook/gatsby-browser.js'),
      options: {"plugins":[],"delayTimer":1000,"pinterest":{"enable":true,"tall":true,"round":false},"twitter":{"enable":true,"containerSelector":null,"handle":null,"showFollowButton":true,"showTimeline":true,"showFollowerCount":true,"timelineTweetCount":1,"width":null,"height":null,"noHeader":true,"noFooter":true,"noBorders":true,"noScrollbar":true,"transparent":true},"facebook":{"enable":true,"containerSelector":"Home-Comfort-Air-1713459065551004/","profile":null,"width":null,"height":null,"tabs":"timeline, events, messages","hideCover":false,"showFacepile":true,"smallHeader":false,"adaptContainerWidth":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XF1GKXN75R"],"PluginCongig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
